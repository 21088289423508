export const experience = [
  {
    id:1,
    name: "Self-Employed-1",
    position: "Software Developer",
    date: "May 2021 - Present",
    link: "https://www.linkedin.com/company/indpendent-contractor/",
    works: [
      "Developed web apps using React, Node.js, and Vue.js",
      "Built a payment service provider API using Laravel.",
      "Introduced engineering standards, including Git flow, CI, unit testing, and containerization.",
      "Developed a React/Redux app from the ground up.",
      "Introduced state-of-the-art technology and stable technology into a new project.",
      "Architected the app structure, including the front-end codebase, front-end API proxy and server, deployment pipeline, and CI.",
      "Worked alongside the CTO, introducing best practices, robust solutions, to all aspects of the app, from UI/UX to API communication and authentication."
    ],
  },
  {
    id:2,
    name: "Self-Employed-2",
    position: "Wordpress and Bubble.io Developer",
    date: "May 2021 - Present",
    link: "https://www.linkedin.com/company/indpendent-contractor/",
    works: [
      "Specialized in services for MVP and Startups",
      "Solve business needs, not only coding;",
      "Top-rated Plus Agency;",
      "Experienced team of project managers makes the building of software an easy routine.",
    ],
  },
  {
    id:3,
    name: "Webdevium",
    position: "Full Stack Developer",
    date: "Sep 2019 - May 2021",
    link: "https://webdevium.com/",
    works: [
      "Developed headless CMS with React.js, Node.js and Strapi ",
      "Upgraded a powerful SaaS using Vue.js and Vuex, Vuetify framework ",
      "Updated and added new RESTful apis by using Apollo GraphQL + AWS Lambda function ",
      "Improved search algorithm and architecture (+30% Big O) ",
      "Implemented Aherf service for SEO and added JSON-LD for better search user experience. ",
      "Increased CTR(Clickthrough rate) depends on A/B tests (+24% improved) ",
      "Integrated payment gateway (stripe api) and localized by using i18n",
      "Transformed localStorage-based state management to Vuexbased system - Contributed to the in-browser slide generator and renderer using VueJS",
    ],
  },
  {
    id:4,
    name: "Vendasta",
    position: "Full Stack Developer",
    date: "Jun 2018 - Aug 2019",
    link: "https://www.linkedin.com/company/203666/",
    works: [
      "Improved a FinTech platform in the Frontend side and API implementation in React",
      "Developed mobile app from desktop web version with Firebase",
      "Added 30+ components to be used in global app by using styled-components",
      "Redesigned global state management system with Redux thunk Simplified and improved UX from new design",
      "Improved performance by optimizing codes with modern JS and api handling ",
      "Developed Admin panel with React and Node",
    ],
  },
  {
    id:5,
    name: "Convergence",
    position: "MERN Stack Developer",
    date: "Aug 2017 - Jun 2018",
    link: "https://cctech.io/",
    works: [
      "Upgraded architecture of an enterprise clothing platform React, Node, MongoDB, Firebase (+7% improved in number of requests per second)",
      "Developed headless CRM by using Strapi, Node and React",
      "Redesigned database MySQL to MongoDB",
      "Developed price analysis platform depend on Price api and Google Merchant api with React and .Net",
      "Set up and administered a continuous integration system based on CruiseControl.NET, including nightly builds with unit tests run and delivery to the test servers. ",
      "Switched to TeamCity for database scripts, versioning, and updating the system.",
      "Optimized the website's performance and planned for the web farm scaling: caching, using CDN, styles and scripts bundling, and minification.",
      "Created and modified the database stored procedures, views, and functions.",
    ],
  },
  {
    id:6,
    name: "Vooban",
    position: "Full Stack Developer",
    date: "Aug 2017 - Jun 2018",
    link: "https://vooban.com/",
    works: [
      "Acted as a Full Stack developer in a 30+ dev team for 2 years",
      "Improved UI/UX with React, Node, styled components, d3.js, TradingView",
      "Developed and improved performance of +10 animation effects with custom styling",
      "Simplified global state management by using Mobx ",
      "Suggested and Integrated real time communication mechanism with Socket.IO and ws(npm package)",
    ],
  },
];
