
export const about = {
        id: 1,
        myself:[
            'I am a professional and highly skilled blockchain developer having more than 5 years of experience in this field. I have done many projects with organizations and clients over the world in these years and have gain much experience and standards to provide a best quality products.',
            'Fast-forward to today, and I was worked at a start-up, and a software company, etc. My main focus these days is building accessible, inclusive products and digital experiences at software company for a variety of clients.',
            ''
        ],
        technologies:[
            'JavaScript','TypeScript','PHP','Python','React','Redux','Vue','Angular','Next','Nuxt','Gatsby','Node.JS','Express','Nest.JS','.Net','Laravel','Django','React Native','Flutter','AWS Amplify','AWS Lambda','Amazon DynamoDB','S3','AWS Cognito','EC2','Cloud Firestore','Firebase Realtime database','Firebase gateway','Github','Gitlab','Bitbucket','Docker','CI/CD','Webpack','Jest','Mocha','Agile','Scrum','Kanban','Waterfall','TDD','SOLID','DRY','Software Engineering','Reverse Engineering','Artificial Intelligence'
        ],
}
