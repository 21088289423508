export const works = [
    {
        id:1,
        title:'Airbnb Clone APP',
        description:'Hiii, this project is based on Airbnb UI. So, I am just copy the airbnb UI and try to build like as that UI. Moreover, it was my learing process. Ha ha !!!',
        technologies:['React','CSS','Redux','Node Js'],
        github_link:'https://github.com/rasel-031/airbnb-clone',
        live_link:'https://airbnb031.netlify.app/',
    },
    {
        id:2,
        title:'RT Software World',
        description:'This project is based on Exhibition Software. You can see and download windows and android applications through this website. React JS is used to developing the Front-End site and Firebase is used for the Back-End.',
        technologies:['React','CSS',"Firebase"],
        github_link:'https://github.com/rasel-031/software-world',
        live_link:'https://software-world.netlify.app/',
    }
    ,
    {
        id:3,
        title:'E2B Dictionary WebApp',
        description:'This is an English to English dictionary. You can search your word and get definitions and examples of that word. This is a React JS-based Application. It has used dictionary API to develop this application.',
        technologies:['React','CSS','Third Party API'],
        github_link:'https://github.com/rasel-031/e2e-dictionary',
        live_link:'https://e2e-dictionary.netlify.app/',
    },
    {
        id:4,
        title:'Restaurant App',
        description:'This project is based on Restaurant. You can see all food items through this website. It is a single page application.',
        technologies:['React','CSS','Local Data'],
        github_link:'https://github.com/rasel-031/food-website',
        live_link:'https://food-website-031.netlify.app/',
    },
    {
        id:5,
        title:'Language Expo Website',
        description:'This project is based on programming language. You can learn about programming language through this website. It is a single page application.',
        technologies:['React','CSS','Local Data'],
        github_link:'https://github.com/rasel-031/language-expo',
        live_link:'https://language-expo.netlify.app/',
    },
    {
        id:6,
        title:'Shop Bay Website',
        description:'This is an e-commerce based project. The React and Redux are used to develop this project. And, also third party free API is used for backend.',
        technologies:['React','Redux','Third Party API'],
        github_link:'https://github.com/rasel-031/shop-bay',
        live_link:'https://shopbay.netlify.app/',
    },
    {
        id:7,
        title:'Time Counter App',
        description:'This is a time counter project. You can add your timer several times with differnt users.',
        technologies:['HTML','JavaScript','Tailwind CSS'],
        github_link:'https://github.com/rasel-031/Time-counter',
        live_link:'https://stop-watch-031.netlify.app/',
    },
    {
        id:8,
        title:'Form Validation',
        description:'This is a form validation react project. You can validate your user validation using Chakra UI.',
        technologies:['React','CSS','Chakra UI'],
        github_link:'https://github.com/rasel-031/Coursera-Advanced-React-Course/tree/main/Lab02',
        live_link:'https://form-validation-031.netlify.app/',
    },
]